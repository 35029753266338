import { EsSearchResponse, EsSinistre } from "types/es";
import axiosClient from "./axiosClient";

const sinistreApi = {

  async esSearch(txt: string): Promise<EsSearchResponse<EsSinistre>> {
    return axiosClient.get(`/es/sinistre/search?txt=${txt}`);
  }

};

export default sinistreApi;
