/** @jsxImportSource @emotion/react */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { css } from '@emotion/react';
import { jsx } from '@emotion/react';

// /** @jsxImportSource @emotion/react */
// /** @jsxRuntime classic */

import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import { Typography } from '@mui/material';

import { ILayout, IMenuItem, INavigationEntry } from 'types/types';
import {
  INavigationState,
  NavigationContext,
  NAVIGATION_ACTION_TYPES,
} from 'contexts/navigationContext';
import Menu from 'components/Menu/menu';
import Logo from 'assets/logo.svg';
import {
  HelpCircleIcon,
  Settings01Icon,
  Bell01Icon,
} from 'components/Icons/icons';
import menuItems from './menuItems';
import styles from './style.js';
import Search from './search/search';
import AutocompleteBar from 'components/SearchBar/autocompleteBar';

const GlobalLayout: React.FC<ILayout> = (props: ILayout) => {
  const { children } = props;
  const history = useHistory();
  const { state, dispatch } = useContext<INavigationState>(NavigationContext);

  const handlePageChange = (menuItem: IMenuItem): void => {
    const navigationEntry: INavigationEntry = {
      id: menuItem.id,
      label: menuItem.label,
      link: menuItem.link,
    };

    dispatch({
      type: NAVIGATION_ACTION_TYPES.startNavigation,
      payload: navigationEntry,
    });

    history.push(menuItem.link);
  };

  return (
    <div css={styles.layoutContainer}>
      <div css={styles.leftBar}>
        <div css={styles.logoWrapper}>
          <img src={Logo} height={118} alt='Logo'/>
        </div>
        <div css={styles.menuWrapper}>
          <Menu items={menuItems} onClick={handlePageChange} />
        </div>
      </div>
      <div css={styles.headerAndContent}>
        <div css={styles.headerBar}>
          {/* <Search /> */}
          <AutocompleteBar />
          <Grid container spacing={4} sx={styles.buttonsWrapper}>
            <Grid item xs="auto">
              <IconButton onClick={() => {}}>
                <HelpCircleIcon />
              </IconButton>
            </Grid>
            <Grid item xs="auto">
              <IconButton onClick={() => {}}>
                <Bell01Icon />
              </IconButton>
            </Grid>
            <Grid item xs="auto">
              <IconButton onClick={() => {}}>
                <Settings01Icon />
              </IconButton>
            </Grid>
            <Grid item xs="auto">
              <div css={styles.userImage}>
                <Typography
                  sx={{ textTransform: 'uppercase', fontWeight: 'bold' }}
                  variant="body1"
                >
                  PM
                </Typography>
              </div>
            </Grid>
          </Grid>
        </div>
        <div css={styles.content}>{children}</div>
      </div>
    </div>
  );
};

export default GlobalLayout;
